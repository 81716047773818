<template>
  <StudioTemplate
    bannerSrc="/assets/image/about-studio/studio_banner.jpg"
    :pageTitle="$t('label.creativeCookingSpace')"
    :pageSubtitle="$t('label.spaceToEmbraceCreativeContent')"
    :isPDF="true"
    topic="faqs"
  ></StudioTemplate>
</template>

<script>
  import StudioTemplate from '@/views/components/studio/StudioTemplate';

  export default {
    name: 'AboutStudio',
    components: {
      StudioTemplate
    }
  };
</script>

<style></style>
