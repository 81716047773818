<template>
  <div class="secondary--text">
    <div class="">
      <h1 class="">{{ $t('label.bookNow') }}</h1>
      <p>{{ $t('label.bookNowSub') }}</p>
    </div>
    <div class="secondary rounded-lg">
      <v-form
        class="content-wrapper white--text py-7 py-lg-15"
        ref="bookingForm"
      >
        <template v-for="(i, index) in formFields">
          <div :key="index">
            <div class="surface--text text-capitalize px-6 mb-2">
              {{ $t(`label.${i.label}`) }}
            </div>
            <AppFormField
              v-model="bookingObj[i.key]"
              :type="i.type"
              :solo="true"
              :flat="true"
              :rounded="true"
              :rules="i.rules"
              :range="false"
              :maxDate="''"
              :allowShowPassword="true"
              autocapitalize="none"
              color="secondary"
              backgroundColor="text"
            >
            </AppFormField>
          </div>
        </template>
        <div class="text-center">
          <AppButton
            :xLarge="false"
            :minHeight="'20px'"
            :minWidth="'20px'"
            class="ml-sm-2 text-caption text-sm-body-2"
            @click="bookingAction"
            >{{ $t('label.request') }}
          </AppButton>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
  import common from '@/core/mixin/common.mixin';
  import { formValidationHelper, i18nHelper } from '@/core/utils';
  import {
    STUDIO_CREATE_BOOKING,
    STUDIO_INITIAL_CREATE_BOOKING_STATE
  } from '@/core/store/studio.module';

  export default {
    name: 'StudioBooking',
    mixins: [common],
    component: {},
    data: () => ({
      formValidation: formValidationHelper,
      bookingObj: {
        contactPersonName: null,
        contactNumber: null,
        preferredDate: null
      },
      formFields: [
        {
          key: 'contactPersonName',
          rules: formValidationHelper.requiredRules(),
          label: 'contactPersonName'
        },
        {
          key: 'contactNumber',
          rules: formValidationHelper.mobileNumberRules(),
          label: 'contactNumber'
        },
        {
          key: 'preferredDate',
          rules: formValidationHelper.requiredRules(),
          label: 'preferredDate',
          type: 'date'
        }
      ]
    }),
    computed: {
      bookingComplete() {
        return this.$store.state.studio.createBooking.complete;
      }
    },
    watch: {
      bookingComplete() {
        let response = this.$store.state.studio.createBooking;

        if (response.complete) {
          this.bookingCompleteAction(response);
        }
      }
    },
    methods: {
      resetForm() {
        this.$refs.bookingForm.reset();
      },
      bookingCompleteAction(response) {
        let title = i18nHelper.getMessage('label.studioBooking');
        let buttons = [
          {
            color: 'secondary',
            text: i18nHelper.getMessage('label.ok'),
            action: () => {
              this.closeAppDialogInfo();
            }
          }
        ];
        let description = response.message;
        if (response.code == 0) {
          let type = 'success';
          this.openAppDialogInfo(type, title, description, buttons);
          this.resetForm();
        } else {
          let type = 'error';
          this.openAppDialogInfo(type, title, description, buttons);
        }
        this.initialBookingState();
      },
      bookingAction() {
        let data = this.bookingObj;
        if (this.$refs.bookingForm.validate()) {
          this.booking(data);
        }
      },
      booking(data) {
        this.$store.dispatch(STUDIO_CREATE_BOOKING, { data });
      },
      initialBookingState() {
        this.$store.dispatch(STUDIO_INITIAL_CREATE_BOOKING_STATE);
      }
    }
  };
</script>

<style></style>
