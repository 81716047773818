<template>
  <PageLayout
    :bannerSrc="bannerSrc"
    :pageTitle="pageTitle"
    :pageSubtitle="pageSubtitle"
    pageTitleDividerClass="primary"
    :color="color"
    layoutType="x"
    :hideLogo="true"
  >
    <div class="content-wrapper pt-6 pb-12">
      <!-- 1st section is About intro studio and some studio image ::start-->
      <section
        v-intersect="{
          handler: onIntersectFirstSection,
          options: {
            threshold: 1
          }
        }"
      >
        <!-- StudioAbout ::start -->
        <transition name="slide-fade-x">
          <v-card v-show="isShowFirstSection" flat class="enter-delay-750ms">
            <StudioAbout></StudioAbout>
          </v-card>
        </transition>
        <!-- StudioAbout ::end -->

        <!-- StudioImg360 ::start -->
        <transition name="slide-fade-x">
          <v-card v-show="isShowFirstSection" flat class="enter-delay-1000ms">
            <StudioImg360 :isMobile="isMobile"></StudioImg360>
          </v-card>
        </transition>
        <!-- StudioImg360 ::end -->
      </section>
      <!-- 1st section is About intro studio and some studio image ::end-->

      <!-- 2nd section is booking studio system ::start -->
      <section v-intersect="onIntersectSecondSection">
        <transition>
          <v-card v-if="isShowSecondSection" flat class="enter-delay-1250ms">
            <StudioBooking></StudioBooking>
          </v-card>
        </transition>
      </section>
      <!-- 2nd section is booking studio system ::end -->
    </div>
  </PageLayout>
</template>

<script>
  import PageLayout from '@/views/layout/PageLayout';
  import StudioAbout from '@/views/components/studio/StudioAbout';
  import StudioImg360 from '@/views/components/studio/StudioImg360';
  import StudioBooking from '@/views/components/studio/StudioBooking';

  export default {
    name: 'StudioTemplate',
    components: {
      PageLayout,
      StudioAbout,
      StudioImg360,
      StudioBooking
    },
    props: {
      bannerSrc: {
        type: String,
        default: ''
      },
      pageTitle: {
        type: String,
        default: ''
      },
      pageSubtitle: {
        type: String,
        default: ''
      },
      color: {
        type: String,
        default: 'surface'
      }
    },
    data: () => ({
      isShowFirstSection: false,
      isShowSecondSection: false,
      isShowThirdSection: false,
      isShowFourthSection: false,
      isShowFifthSection: false,
      isIntersectPageLayout: false
    }),
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.mobile;
      }
    },
    methods: {
      owlNext() {
        document.getElementById('owlNext').click();
      },
      onIntersectFirstSection(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowFirstSection = true;
      },
      onIntersectSecondSection(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowSecondSection = true;
      }
    }
  };
</script>

<style lang="scss"></style>
