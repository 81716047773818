<template>
  <div class="secondary--text pb-15">
    <h1 class="pb-5">{{ $t('label.Studio360') }}</h1>
    <iframe
      width="100%"
      :height="isMobile ? '200px' : '500px'"
      class="rounded-xl"
      :src="studio360"
      frameborder="0"
      loading
    ></iframe>

    <div class="mt-7" style="position: relative">
      <viewer :images="studioImg">
        <Carousel
          :class="''"
          :items="isMobile ? 3 : 5"
          :autoplay="true"
          :rewind="true"
          :dots="false"
          :nav="false"
        >
          <template v-for="i in studioImg">
            <img class="px-2" :key="i.src" :src="i.src" />
            <!-- <viewer :images="studioImg">
        <img v-for="src in studioImg" :key="src" :src="src.src" width="50px" />
      </viewer> -->
          </template>
          <template slot="prev"
            ><span hidden ref="prev" class="prev">prev</span></template
          >
          <template slot="next"
            ><span hidden ref="next" class="next">next</span></template
          >
        </Carousel>
      </viewer>

      <span
        style="
          position: absolute;
          top: 50%;
          left: 3%;
          transform: translate(-50%, -50%);
          z-index: 1;
        "
        @click="$refs.prev.click()"
      >
        <v-icon class="" style="font-size: 1.5rem" color="white">
          mdi-arrow-left-drop-circle-outline</v-icon
        >
      </span>
      <span
        style="
          position: absolute;
          top: 50%;
          right: 1%;
          transform: translate(-50%, -50%);
          z-index: 1;
        "
        @click="$refs.next.click()"
      >
        <v-icon class="" style="font-size: 1.5rem" color="white">
          mdi-arrow-right-drop-circle-outline</v-icon
        >
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'StudioImg360',
    props: {
      isMobile: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      studio360: 'https://my.matterport.com/show/?m=xmYuoNNPw9m',
      studioImg: [
        {
          src: '/assets/image/about-studio/studio_1.png'
        },
        {
          src: '/assets/image/about-studio/studio_2.png'
        },
        {
          src: '/assets/image/about-studio/studio_3.png'
        },
        {
          src: '/assets/image/about-studio/studio_4.png'
        },
        {
          src: '/assets/image/about-studio/studio_5.png'
        },
        {
          src: '/assets/image/about-studio/studio_6.png'
        },
        {
          src: '/assets/image/about-studio/studio_7.png'
        },
        {
          src: '/assets/image/about-studio/studio_8.png'
        }
      ]
    }),
    methods: {}
  };
</script>

<style></style>
