<template>
  <div class="secondary--text pb-15">
    <h1 class="font-weight-bold py-4">{{ $t('aboutStudio.title') }}</h1>
    <p>
      {{ $t('aboutStudio.message') }}
    </p>
    <div class="font-weight-bold pt-2">{{ $t('aboutStudio.subTitle') }}</div>
    <div class="">
      <div>{{ $t('aboutStudio.point1') }}</div>
      <div>{{ $t('aboutStudio.point2') }}</div>
      <div>{{ $t('aboutStudio.point3') }}</div>
      <div>{{ $t('aboutStudio.point4') }}</div>
      <div>{{ $t('aboutStudio.point5') }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'StudiioAbout'
  };
</script>

<style></style>
